<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "RatingElement",
  mixins: [layoutMixin],
  props: {
    rating: Number,
  },
  data() {
    return {
      stars: [1, 2, 3, 4, 5],
      fontSize: "15px",
    };
  },
  render: function(createElement) {
    var renderedIcon = document.getElementById("icon-rendered");
    var innerHTML =
      renderedIcon.innerHTML +
      renderedIcon.innerHTML +
      renderedIcon.innerHTML +
      renderedIcon.innerHTML +
      renderedIcon.innerHTML;

    this.adaptFont(innerHTML);

    var starStyle = {
      color: this.layout.color,
    };

    // text class
    var align = "";
    var vAlign = "";
    var txtClass = "flex flex-row";
    if (this.layout) {
      switch (this.layout.vAlign) {
        case 0:
          vAlign = "items-start";
          break;
        case 1:
          vAlign = "items-center";
          break;
        case 2:
          vAlign = "items-end";
          break;
      }

      switch (this.layout.align) {
        case 0:
          align = "justify-start text-left";
          break;
        case 1:
          align = "justify-center text-center";
          break;
        case 2:
          align = "justify-end text-right";
          break;
      }
    }

    var children = [];

    var roundedRating = Math.round(this.rating * 2) / 2;

    this.stars.forEach((star) => {
      var sIcon = ["far", "star"];
      if (star <= roundedRating) {
        sIcon = ["fas", "star"];
      }

      if (star - 0.5 === roundedRating) {
        sIcon = ["fas", "star-half-alt"];
      }

      children.push(
        createElement("font-awesome-icon", {
          style: [starStyle],
          props: {
            icon: sIcon,
          },
        })
      );
    });

    return createElement(
      "div",
      {
        class: [
          this.layoutClass,
          this.bgClass,
          txtClass,
          this.customClass,
          vAlign,
          align,
        ],
        style: [
          this.layoutStyle,
          this.bgStyle,
          this.textStyle,
          this.customStyle,
        ],
      },
      children
    );
  },
};
</script>
